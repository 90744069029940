<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},

  mounted() {
    var temp_path = window.location.pathname;
    console.log("temp_path===>", temp_path);
    if (this.$AuthFunc.isMobile()) {
      this.$router.replace("/mobile/index");
    } else {
      console.log("pc端");
    }
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
