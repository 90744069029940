<template>
  <div class="top-menu-box">
    <div class="logo" @click="$router.push('/')" style="cursor: pointer">
      <img 
      :src="$i18n.locale=='zh'?ZnlogoSrc:EnglishlogoSrc"
       class="logoStyle" />
    </div>
    <div class="menu-right">
      <div class="top-menu-item" @click="scrollClick('IndexID')">
        {{ $t("firstIndex") }}
      </div>

      <div class="top-menu-item" @click="clickHandle('/price')">
        {{ $t("topMenu.menu03") }}
      </div>

      <!-- 国际化 -->
      <div class="top-menu-item">
        <el-dropdown trigger="click" style="color: #fff">
          <span class="el-dropdown-link">
            <div class="link-box">
              <img
                src="@/assets/code/guojihua.png"
                style="width: 18px; height: 18px; margin-right: 10px"
              />
              {{ currentLang == "zh" ? $t("language") : "English"
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </div>
          </span>

          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="switchHandle('zh')"
              >简体中文</el-dropdown-item
            >
            <el-dropdown-item @click.native="switchHandle('en')"
              >English</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <div class="menu-action-box" v-if="!username">
        <div class="top-menu-login" @click="clickHandle('/login')">
          {{ $t("login") }}
        </div>
        <div class="top-menu-login" @click="clickHandle('/register')">
          {{ $t("register") }}
        </div>
      </div>
      <div class="top-menu-item" v-if="username">
        <el-dropdown trigger="click" style="color: #fff">
          <span class="el-dropdown-link">
            <div class="link-box">
              <i
                class="el-icon-user-solid"
                style="font-size: 18px; margin-right: 10px"
              />

              {{ username }}<i class="el-icon-arrow-down el-icon--right"></i>
            </div>
          </span>
          <el-dropdown-menu slot="dropdown">
           
            <el-dropdown-item> {{$t("Setting.Profile")}} </el-dropdown-item>
            <el-dropdown-item @click.native="$router.push('/password/find')">
              {{$t("Setting.Password")}}
            </el-dropdown-item>
            <el-dropdown-item @click.native="outHandle()">{{
              $t("logout")
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { sendLoginOut } from "@/api/loginApi";

export default {
  data() {
    return {
      username: "",
      ZnlogoSrc:require("@/assets/code/logo-cn.png"),
      EnglishlogoSrc: require("@/assets/code/logo-en.png"),
      currentLang:localStorage.getItem('language') || "en",
      topMenuList: [
        {
          title: "登录",
          url: "/login",
          needquery: true,
        },
        {
          title: "注册",
          url: "/register",
          needquery: true,
        },
        {
          title: "产品/价格",
          url: "/price",
          needquery: false,
        },
      ],
    };
  },

  mounted() {
    this.username = this.$AuthFunc.getUserName();
    // this.$AuthFunc.getUserName()
    // JSON.parse(localStorage.getItem("userInfo") || "{}").username || "";
  },
  methods: {
    // 切换语音
    switchHandle(value) {
      this.$store.commit("setUpdateLang", value);
      this.$i18n.locale = value;
      this.currentLang = value
      localStorage.setItem("language", value);
    },
    clickHandle(url) {
      const baseUrl = window.location.origin; // 获取当前网站的基础URL
      const fullUrl = `${baseUrl}${url}`;
      // 在新窗口打开
      window.open(fullUrl, "_blank");
    },
    scrollClick(elementId) {
      this.$router.push("/");
      this.$store.commit("setUpdateClickElement", elementId);
    },
    outHandle() {
      sendLoginOut({}).then((res) => {
        if (res.data.code == 200) {
          localStorage.clear();
          this.username = "";
          this.$message.success("用户已退出");
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.link-box {
  display: flex;
  align-items: center;
  // margin-right: 20px;
}
.menu-action-box {
  display: flex;
  .top-menu-login {
    cursor: pointer;
    padding: 0 10px;
    font-size: 16px;
  }
}

.top-menu-box {
  display: flex;
  justify-content: space-between;
  background: #111111;
  color: #fff;
  // height: 80px;
  // line-height: 80px;
  font-size: 22px;
  padding: 0 5% 0 20px;

  .menu-right {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .top-menu-item {
    width: 120px;
    text-align: center;
    cursor: pointer;
    &:hover {
      color: antiquewhite;
    }
  }
}

.logoStyle {
  width: 220px;
  
  padding: 12px 0 12px 12px;
  height: 55px;

}
</style>
