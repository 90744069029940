import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './permission.js'
import './global.scss'
Vue.use(ElementUI)

import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

import VueI18n from 'vue-i18n'
import i18n from '../src/i18n'




import AuthFunc from './utils/auth'
import { TranslatedMessage } from  './utils/constants'
Vue.prototype.$AuthFunc = AuthFunc
Vue.prototype.$TranslatedMessage = TranslatedMessage

Vue.config.productionTip = false

new Vue({
  
  render: h => h(App),
  router,
  store,
  i18n,
  VueI18n,
}).$mount('#app')
