<template>
  <div class="bottom-box">
    <div class="bottom-middle">
      <div class="left-box">
        <div class="list">
          <div class="title">
            {{ $t("footer.CompanyProfile") }}
          </div>
          <div class="item" @click="openNewPage('/about/kmks')" style="cursor: pointer;">
            {{ $t("footer.introduction") }}
          </div>
          <div class="item">
            <a
              :href="UserhrefLinkSource"
              target="view_window"
              class="linkStyle"
            >
              {{ $t("footer.UserAgreement") }}</a
            >

            <!-- /web/user.html -->
          </div>
          <div class="item">
            <a
              :href="PrivacyhrefLinkSource"
              target="view_window"
              class="linkStyle"
            >
              {{ $t("footer.Privacy") }}</a
            >
          </div>
        </div>

        <div class="list">
          <div class="title">{{ $t("footer.Aboutus") }}</div>
          <div class="item contentus">
            <span class="item-text"> {{ $t("footer.ContactUs") }} </span>
            <div class="qr-box contentCode">
              <img src="../assets/code/kamikasi.jpg" class="codeStyle" />
            </div>
          </div>
          <div class="item">
            {{ $t("footer.CustomerService") }}: support@kamikasi.net
          </div>
          <div class="item hoverstyle">
            <span class="item-text"> {{ $t("footer.TechnicalSupport") }} </span>
            <div class="qr-box hoverCode">
              <img src="../assets/code/kamikasi.jpg" class="codeStyle" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-content">
     
      <img
        id="logo-img"
        :src="$i18n.locale=='zh'?ZnlogoSrc:EnglishlogoSrc"
        alt="default"
        width="120"
      />
      <div class="icp-box">
        <div class="icp-code">苏ICP备2023033387号</div>
        <div>Copyright ©2022-2024 Kamikasi Tech Ltd. All Rights Reserved</div>
      </div>
    </div>
  </div>
</template>

<script>
import IconSvg from "./IconSvg.vue";
// 页脚list

export default {
  props: {
    fromway: {
      type: String,
      default: "",
    },
  },
  components: {
    IconSvg,
  },
  data() {
    return {
      ZnlogoSrc: require("@/assets/code/logo-cn.png"),
      EnglishlogoSrc: require("@/assets/code/logo-en.png"),
      AboutList: [
        {
          title: "关于我们",
          itemList: [
            {
              text: "企业介绍",
              value: "companyIntro",
              url: "/article/company",
              mobileUrl: "/mobile/article/company",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    // console.log("获取的值====：", this.fromway)
  },

  computed: {
    UserhrefLinkSource() {
      return this.$i18n.locale === "zh"
        ? "/web/user-zh.html"
        : "/web/user-en.html";
    },
    PrivacyhrefLinkSource() {
      return this.$i18n.locale === "zh"
        ? "/web/yinsi-zh.html"
        : "/web/yinsi-en.html";
    },
  },
  methods: {
    openNewPage(e) {
      const routeUrl = this.$router.resolve({ path: e });
      window.open(routeUrl.href, "_blank");
    },
    gotoDetail(item) {
      if (this.fromway) {
        if (item.type) {
          this.$router.push(`${item.mobileUrl}/${item.value}`);
        } else {
          this.$router.push(item.mobileUrl);
        }
      } else {
        this.$router.push(`${item.url}/${item.value}`);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.linkStyle {
  color: #fff;
  text-decoration: none;
}
.bottom-middle {
  width: calc(100% - 250px);
  margin-left: 250px;
  // display: flex;
  // justify-content: space-between;
  .left-box {
    width: 100%;
    display: flex;
    justify-content: space-around;
    .list {
      text-align: left;
      .title {
        font-size: 16px;
        font-weight: 500;
        padding: 20px 0;
      }

      .item {
        padding-top: 20px;
        font-size: 14px;
        color: #fff;

        position: relative;

        .qr-box {
          position: absolute;
          top: 20px;
          left: 70px;
          width: 60px;
          height: 60px;
          background-color: aliceblue;
          color: #000;
          display: none;
        }
      }

      .contentus {
        &:hover {
          .contentCode {
            display: block;
          }
        }
      }
      .hoverstyle {
        &:hover {
          .hoverCode {
            display: block;
          }
        }
      }
    }
  }

  .right-box {
    width: 100%;
    .code-box {
      display: flex;
      justify-content: right;
      margin-bottom: 40px;
      .code-item {
        .codeClass {
          width: 90px;
          height: 90px;
        }
        .desc {
          font-size: 12px;
          color: 999;
        }
        &:last-child {
          padding-left: 40px;
        }
      }
    }
  }
}

.bottom-box {
  padding-top: 30px;
  background-color: #000;
  color: #fff;
}

.bottom-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
}
#logo-img {
  margin-right: 20px;
}

.icp-box {
  font-size: 12px;
}

.jinghui {
  font-size: 12px;
  color: #ccc;
  display: inline-block;
  margin-top: 6px;
  padding-left: 20px;
  height: 16px;
  line-height: 16px;
  background: url(https://mbdp01.bdstatic.com/static/landing-pc/img/icon_police.7296bdfd.png)
    0 0 no-repeat;
}

@media screen and (max-width: 500px) {
  /* 在屏幕宽度小于等于768px时应用以下样式 */

  .wrapperBox {
    width: 90%;
    margin: 0 auto;
  }
}

.codeStyle {
  width: 100%;
  height: 100%;
}
</style>
